import * as React from "react";

const SvgFlowLogoMd = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={36}
    width={164.333}
    {...props}
  >
    <path
      style={{
        strokeWidth: 0.256826,
      }}
      fill="#222234"
      d="M54.009.5c-1.813 0-3.325.27-4.538.809a7.386 7.386 0 0 0-2.893 2.184 8.26 8.26 0 0 0-1.512 3.187 16.194 16.194 0 0 0-.439 3.821v2.097H40.68v5.348h3.947V34.41h7.19V17.946h4.793v-5.348h-4.793v-2.183c0-.497.044-.994.131-1.484.078-.47.242-.922.482-1.333a2.59 2.59 0 0 1 .964-.939c.41-.232.95-.349 1.623-.349.352 0 .704.03 1.052.087.313.05.622.122.925.219l.482-5.677A10.329 10.329 0 0 0 55.832.61 15.166 15.166 0 0 0 54.009.5Zm6.548 33.917h7.33V1.345h-7.33zM91.45 15.038a11.624 11.624 0 0 0-3.85-2.275 14.261 14.261 0 0 0-9.341 0 11.33 11.33 0 0 0-3.85 2.271 10.787 10.787 0 0 0-2.603 3.625c-.642 1.427-.962 3.029-.962 4.805a11.73 11.73 0 0 0 .962 4.825 10.96 10.96 0 0 0 2.603 3.67 11.706 11.706 0 0 0 3.85 2.335 13.555 13.555 0 0 0 9.34 0 12.02 12.02 0 0 0 3.85-2.336 10.824 10.824 0 0 0 2.621-3.67c.658-1.523.986-3.167.963-4.826 0-1.776-.321-3.377-.963-4.804a10.655 10.655 0 0 0-2.62-3.625zM87.796 25.52a5.606 5.606 0 0 1-.987 1.812 4.84 4.84 0 0 1-1.621 1.282 5.424 5.424 0 0 1-4.462 0 4.828 4.828 0 0 1-1.615-1.286 5.612 5.612 0 0 1-.987-1.812 6.586 6.586 0 0 1 0-4.106 5.336 5.336 0 0 1 .962-1.768c.44-.522.984-.946 1.598-1.245a5.42 5.42 0 0 1 4.462 0 5.134 5.134 0 0 1 1.618 1.245 5.447 5.447 0 0 1 1.007 1.768c.23.66.35 1.355.35 2.054 0 .696-.11 1.388-.327 2.05zm38.265-12.916-3.816 13.791h-.087l-4.035-13.791h-7.237l-3.904 13.791h-.13l-3.773-13.791h-7.702l7.499 21.672h7.24l4.191-13.51h.087l4.279 13.51h7.24l7.542-21.672z"
    />
    <path
      style={{
        strokeWidth: 0.256826,
      }}
      fill="#2c62cb"
      d="M18.015 0A18.03 18.03 0 0 0 5.276 5.267 17.968 17.968 0 0 0 0 17.984v.043l4.679.998c4.568.976 9.324.572 13.661-1.16a20.282 20.282 0 0 1 11.357-1.08l6.331 1.223v-.026c0-4.769-1.898-9.342-5.276-12.715A18.03 18.03 0 0 0 18.015 0Z"
    />
    <path
      style={{
        strokeWidth: 0.256826,
      }}
      fill="#222234"
      d="m144.486 34.173.057-6.974h-.042l-2.566 6.974h-1.674l-2.49-6.974h-.043l.057 6.974h-2.252v-9.85h3.393l2.249 6.32h.056l2.15-6.32h3.448v9.85zM153.947 26.88a2.158 2.158 0 0 0-.802-.62 2.267 2.267 0 0 0-1.437-.187c-.155.025-.304.08-.44.16-.129.079-.24.183-.327.307a.813.813 0 0 0-.132.48.767.767 0 0 0 .104.422c.079.12.184.22.308.293.152.092.313.169.48.23.188.069.397.14.628.214.334.112.683.235 1.046.37.353.13.685.31.986.535.3.225.55.506.74.828.206.377.307.802.292 1.231a3.212 3.212 0 0 1-.314 1.47c-.2.4-.49.75-.846 1.023a3.625 3.625 0 0 1-1.214.598 5.12 5.12 0 0 1-1.41.194c-.7 0-1.394-.124-2.049-.369a4.34 4.34 0 0 1-1.645-1.051l1.562-1.587c.262.313.588.566.955.744.364.192.768.295 1.179.3.174 0 .347-.019.516-.056.157-.033.306-.095.44-.182a.91.91 0 0 0 .3-.334c.078-.155.116-.327.112-.5a.797.797 0 0 0-.141-.474 1.368 1.368 0 0 0-.398-.354 3.236 3.236 0 0 0-.641-.293 25.564 25.564 0 0 0-.872-.281 8.335 8.335 0 0 1-.927-.362 3.1 3.1 0 0 1-.81-.536 2.485 2.485 0 0 1-.57-.794 2.683 2.683 0 0 1-.216-1.134c-.015-.49.1-.976.334-1.407a2.9 2.9 0 0 1 .878-.962 3.776 3.776 0 0 1 1.227-.55 5.57 5.57 0 0 1 1.38-.174 5.14 5.14 0 0 1 1.717.3 4.41 4.41 0 0 1 1.527.906zM164.331 27.352a3.022 3.022 0 0 1-.32 1.455 2.635 2.635 0 0 1-.864.954c-.383.25-.808.43-1.255.53-.484.112-.98.168-1.477.167h-1.186v3.716h-2.385v-9.85h3.623a6.828 6.828 0 0 1 1.513.16c.437.093.853.268 1.226.515.349.237.632.558.823.933.213.443.316.93.302 1.42zm-2.384.014a1.06 1.06 0 0 0-.14-.571.975.975 0 0 0-.377-.348 1.624 1.624 0 0 0-.537-.167 4.512 4.512 0 0 0-.62-.042h-1.047v2.324h1.004c.215 0 .43-.018.642-.056.193-.033.38-.099.55-.194.158-.088.29-.215.384-.369.1-.176.148-.375.141-.577zM18.34 17.867A23.565 23.565 0 0 1 4.68 19.025l-4.679-1a17.97 17.97 0 0 0 5.282 12.714A18.017 18.017 0 0 0 18.023 36a18.02 18.02 0 0 0 12.736-5.273 17.982 17.982 0 0 0 5.27-12.72l-6.33-1.222a20.282 20.282 0 0 0-11.359 1.082Z"
    />
  </svg>
);

export default SvgFlowLogoMd;
