import React, { Fragment, useState, useCallback  } from 'react';
import { useIntersectionObserver } from '@wojtekmaj/react-hooks';

import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// import { PDFViewer as PDFViewerLib } from '@react-pdf/renderer';

export default function PDFViewer(props) {
  // const [/*numPages*/, setNumPages] = useState(null);
  // const [/*pageNumber*/, setPageNumber] = useState(1);
  const pdfContainerStyle = props.zoomMultiplier && { transform: `scale(${props.zoomMultiplier})` };

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  //   setPageNumber(1);
  // }

  // function changePage(offset) {
  //   setPageNumber(prevPageNumber => prevPageNumber + offset);
  // }

  // function previousPage() {
  //   changePage(-1);
  // }
  //
  // function nextPage() {
  //   changePage(1);
  // }

  return (
    <div className="pdf-container" style={pdfContainerStyle} >
      {/*  FIXME: Add wheel handling or figure out how to make yet-another-react-lightbox not eat the scroll event */}
      <PDFViewerWithScroll {...props}/>
    </div>
  );
}

const observerConfig = {
  threshold: 0
};

function PageWithObserver({ pageNumber, setPageVisibility, ...otherProps }) {
  const [page, setPage] = useState();

  const onIntersectionChange = useCallback(
    ([entry]) => {
      setPageVisibility(pageNumber, entry.isIntersecting);
    },
    [pageNumber, setPageVisibility]
  );

  useIntersectionObserver(page, observerConfig, onIntersectionChange);

  return <Page renderTextLayer renderAnnotationLayer canvasRef={setPage} pageNumber={pageNumber} {...otherProps} />;
}

const DOCUMENT_OPTIONS = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/'
};

function PDFViewerWithScroll(props) {
  const [currentNumberOfPages, setCurrentNumberOfPages] = useState(null);
  const [/*visiblePages*/, setVisiblePages] = useState({});

  function onDocumentLoadSuccess({ numPages }) {
    setCurrentNumberOfPages(numPages);
  }

  const setPageVisibility = useCallback((pageNumber, isIntersecting) => {
    setVisiblePages((prevVisiblePages) => ({
      ...prevVisiblePages,
      [pageNumber]: isIntersecting
    }));
  }, []);


  return (
    <Fragment>
      <Document
        file={props.srcOrig}
        onLoadSuccess={onDocumentLoadSuccess}
        options={DOCUMENT_OPTIONS}
      >
        {(new Array(currentNumberOfPages)).fill(0).map((el, index) => (
          <PageWithObserver
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            setPageVisibility={setPageVisibility}
          />
        ))}
      </Document>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          background: 'white',
          padding: 10,
          border: '1px thin',
          zIndex: 2
        }}
      >
        <a className={'link-out'} target="_blank" rel="noreferrer" href={props.srcOrig}>Open in new window</a>
      </div>
    </Fragment>
  );
}
