import * as React from "react";
import { Lightbox, useLightboxState, useLightboxProps, createIcon, IconButton } from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Captions from "yet-another-react-lightbox/plugins/captions";

import PDFViewer from "./PDFViewer";


export const LightboxComponent = ({ images, lightboxIsOpen, toggleLightbox, startingImageIndex }) => {
  const [currentIndex, setCurrentIndex] = React.useState(startingImageIndex);
  const [showAnnotations, setShowAnnotations] = React.useState(true);
  const [pdfZoomMultiplier, setPdfZoomMultiplier] = React.useState(1);
  const pdfZoomStep = 0.5;
  const pdfMaxZoom = 3;

  const pdfZoomIn = () => {
    const nextValue = pdfZoomMultiplier + pdfZoomStep;
    if (nextValue <= pdfMaxZoom) {
      setPdfZoomMultiplier(nextValue);
    }
  };

  const pdfZoomOut = () => {
    const nextValue = pdfZoomMultiplier - pdfZoomStep;
    if (nextValue >= 1) {
      setPdfZoomMultiplier(nextValue);
    }
  };

  const slides = images.map(img => {
    const isPDF = (img.hrefThumbnail === "NoImage");

    return {
      type: isPDF ? "pdf" : "image",
      title: isPDF ? "" : img.title,
      src: isPDF ? img.hrefOriginal : img.href,
    };
  });

  const annotatedSlides = images.map(img => {
    const isPDF = (img.hrefThumbnail === "NoImage");
    let srcAnnotated = img.href;

    if (img.hrefAnnotated) {
      srcAnnotated = `${img.hrefAnnotated}?${new Date().getTime()}`;
    }

    return {
      type: isPDF ? "pdf" : "image",
      title: isPDF ? "" : img.title,
      src: isPDF ? img.hrefOriginal : srcAnnotated,
    };
  });

  return (
    <Lightbox
      open={lightboxIsOpen}
      close={toggleLightbox}
      index={currentIndex}
      on={{
        view: ({ index }) => {
          setCurrentIndex(index);
          setPdfZoomMultiplier(1);
        },
      }}
      slides={showAnnotations ? annotatedSlides : slides}
      plugins={[Zoom, Thumbnails, Counter, Captions]}
      zoom={{ scrollToZoom: false, maxZoomPixelRatio: 10 }}
      styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
      render={{
        slide: ({ slide, offset, rect }) =>
          slide.type === "pdf" ? (
            (offset < 3 || offset > -3) ? (<PDFViewer
              srcOrig={slide.src}
              zoomMultiplier={pdfZoomMultiplier}
            />) : undefined
          ) : undefined,
        buttonZoom: (zoomRef) => (
          <CustomZoomButtons
            key="custom-zoom-buttons"
            zoomRef={zoomRef}
            pdfZoomIn={pdfZoomIn}
            pdfZoomOut={pdfZoomOut}
            pdfZoomMultiplier={pdfZoomMultiplier}
            pdfMaxZoom={pdfMaxZoom}
          />
        ),
      }}
      toolbar={{
        buttons: [
          <AnnotationsButton key="annotations-button" showAnnotations={showAnnotations} onClick={() => setShowAnnotations(!showAnnotations)} />,
          "zoom",
          "close",
        ],
      }}
      counter={{ container: { style: { top: "unset", bottom: 0 } } }}
      controller={{
        preventDefaultWheelY: false
      }}
      noScroll={{
        disabled: true
      }}
    />
  );
};

const AnnotationsButton = ({showAnnotations, onClick}) => {
  const { currentSlide } = useLightboxState();

  return (
    <button disabled={currentSlide.type === "pdf"} type="button" className="yarl__button" onClick={onClick}>
      { showAnnotations ? "Hide Annotations" : "Show Annotations" }
    </button>
  );
};

const ZoomInIcon = createIcon(
  "ZoomIn",
  <React.Fragment>
    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
    <path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z" />
  </React.Fragment>,
);

const ZoomOutIcon = createIcon(
  "ZoomOut",
  <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z" />,
);

const ZoomButton = ({ isZoomIn, disabled, zoomInCallback, zoomOutCallback }) => {
  const { render } = useLightboxProps();

  return (
    <IconButton
      disabled={disabled}
      label={isZoomIn ? "Zoom in" : "Zoom out"}
      icon={isZoomIn ? ZoomInIcon : ZoomOutIcon}
      renderIcon={isZoomIn ? render.iconZoomIn : render.iconZoomOut}
      onClick={isZoomIn ? zoomInCallback : zoomOutCallback}
    />
  );
};

const CustomZoomButtons = ({ zoomRef, pdfZoomIn, pdfZoomOut, pdfZoomMultiplier, pdfMaxZoom }) => {
  const { currentSlide } = useLightboxState();

  const zoomInDisabled = React.useMemo(() => {
    const newZoom = (currentSlide.type === "pdf") ? pdfZoomMultiplier : (zoomRef && zoomRef.zoom);
    const newMaxZoom = (currentSlide.type === "pdf") ? pdfMaxZoom : (zoomRef && zoomRef.maxZoom);
    return newZoom >= newMaxZoom;
  }, [zoomRef, pdfZoomMultiplier, pdfMaxZoom, currentSlide.type]);

  const zoomOutDisabled = React.useMemo(() => {
    const newZoom = (currentSlide.type === "pdf") ? pdfZoomMultiplier : (zoomRef && zoomRef.zoom);
    return newZoom <= 1;
  }, [zoomRef, pdfZoomMultiplier, currentSlide.type]);

  const zoomInCallback = () => {
    if (currentSlide.type === "pdf") {
      pdfZoomIn();
    } else {
      zoomRef && zoomRef.zoomIn();
    }
  };

  const zoomOutCallback = () => {
    if (currentSlide.type === "pdf") {
      pdfZoomOut();
    } else {
      zoomRef && zoomRef.zoomOut();
    }
  };

  return (
    <React.Fragment>
      <ZoomButton isZoomIn disabled={zoomInDisabled} zoomInCallback={zoomInCallback} zoomOutCallback={zoomOutCallback} />
      <ZoomButton isZoomIn={false} disabled={zoomOutDisabled} zoomInCallback={zoomInCallback} zoomOutCallback={zoomOutCallback} />
    </React.Fragment>
  );
};
