import axios from 'axios';
import { store } from '../index';

export function AJAX(request) {
  const state = store.getState();
  const jwt = state && state.session && state.session.jwt;
  const tokenType = state && state.session && state.session.tokenType;
  let res;

  return axios({
    ...request,
    headers: {
      Authorization: `${tokenType} ${jwt}`,
      'X-FlowMSP-Source': 'Web',
      'X-FlowMSP-Version': process.env.REACT_APP_VERSION
    }
  }).then(response => {
    res = response;
    return res;
  }).catch(err => {
    console.error('AJAX request error', err);
  });
}

export function AJAXUpload(request) {
  const state = store.getState();
  const jwt = state && state.session && state.session.jwt;
  const tokenType = state && state.session && state.session.tokenType;

  return axios({
    method: request.method,
    url: request.url,
    data: request.data,
    headers: {
      Authorization: `${tokenType} ${jwt}`,
      'Content-Type': 'multipart/form-data',
      'X-FlowMSP-Source': 'Web',
      'X-FlowMSP-Version': process.env.REACT_APP_VERSION
    },
    config: request.config
  });
}
