import config from '../config.js';
import * as AJAXUtil from './AJAXUtil';


export function getAllUnits() {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${config.API_BASE_URL}/api/unit`
  });
}

// export function getUnit(unitId) {
//   return AJAXUtil.AJAX({
//     method: 'GET',
//     url: `${config.API_BASE_URL}/api/unit/${unitId}`
//   });
// }

export function addUnit(data) {
  return AJAXUtil.AJAX({
    method: 'PUT',
    data: data,
    url: `${config.API_BASE_URL}/api/unit`
  });
}

export function editUnit(unitId, data) {
  return AJAXUtil.AJAX({
    method: 'PATCH',
    data: data,
    url: `${config.API_BASE_URL}/api/unit/${unitId}`
  });
}

export function deleteUnit(unitId) {
  return AJAXUtil.AJAX({
    method: 'DELETE',
    url: `${config.API_BASE_URL}/api/unit/${unitId}`
  });
}
