import React from 'react';
import { Box, Divider, Heading, Stack, useColorModeValue, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import FlowLogo from '../../svg/FlowLogoMd';
import FormComponent from '../form/FormComponent';
import { SubmitButton } from '../form/Button';
import * as sessionAPI from '../../api/SessionAPI';
import { zodResolver } from '@hookform/resolvers/zod';
import { PasswordResetComplete } from '../../models/PasswordReset';
import {useNavigate} from "react-router-dom";
// import { push } from 'connected-react-router';

const PasswordResetComponent = (props) => {
  const methods = useForm({
    resolver: zodResolver(PasswordResetComplete)
  });
  const toast = useToast();
  const navigate = useNavigate();

  const getTokenValue = (name) => {
    const url = window.location.href;
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  const onSubmit = async(values) => {

    return new Promise(resolve => sessionAPI.forgotPasswordReset({
      resetRequestId: getTokenValue('resetRequestId'),
      email: values.email,
      newPassword: values.newPassword
    },
      () => {
        toast({
          title: 'Success',
          position: 'top',
          description: 'Password reset successfully',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
        resolve();
        navigate('/login');
      },
      () => {
        toast({
          title: 'Error',
          position: 'top',
          description: 'There was an error resetting your password, please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
        resolve();
      }));

  };

  const formFields = [
    {
      id: 'email',
      name: 'email',
      type: 'email',
      label: 'Email',
      required: true
    },
    {
      id: 'newPassword',
      name: 'newPassword',
      type: 'password',
      label: 'New Password',
      required: true
    },
    {
      id: 'confirmPassword',
      name: 'confirmPassword',
      type: 'password',
      label: 'Confirm Password',
      required: true,
      confirmPasswordCheck: true
    }
  ];

  return (
    <Box
      rounded={'xl'}
      bg={useColorModeValue('white', 'gray.700')}
      boxShadow={'xl'}
      minWidth="24rem"
    >
      <Stack spacing="1rem" mx={'auto'} maxW={'xl'} py={6} px={6}>
        <Box align="center"><FlowLogo /></Box>
        <Divider />
        <Heading as="h4" size="md">Reset Password</Heading>
        <Box
          bg={useColorModeValue('white', 'gray.700')}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <FormComponent formFields={formFields} data={null} />
              <SubmitButton width="100%" isLoading={methods.formState.isSubmitting} mt={4} type="submit">
                Reset Password
              </SubmitButton>
            </form>
          </FormProvider>
        </Box>
      </Stack>
    </Box>
  );
};


export default PasswordResetComponent;
