import * as React from 'react';

const SvgFlowLogo28 = (props) => (
  <svg
    fill="none"
    height={28}
    width={127.814}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      style={{
        strokeWidth: 0.199755,
      }}
      fill="#222234"
      d="M42.007.39c-1.41 0-2.586.21-3.53.628-.88.37-1.652.955-2.25 1.699a6.424 6.424 0 0 0-1.176 2.479 12.595 12.595 0 0 0-.34 2.972v1.63h-3.07v4.16h3.07v12.806h5.591V13.958h3.728v-4.16h-3.728V8.101c0-.387.034-.774.102-1.155.06-.365.188-.717.375-1.037.181-.305.44-.556.75-.73.318-.18.739-.271 1.262-.271.274 0 .548.023.818.068.244.038.484.095.72.17L44.702.73a8.033 8.033 0 0 0-1.278-.255C42.955.418 42.48.39 42.007.39ZM47.1 26.769h5.702V1.046H47.1Zm24.027-15.073a9.04 9.04 0 0 0-2.995-1.77 11.092 11.092 0 0 0-7.265 0 8.813 8.813 0 0 0-2.994 1.767 8.39 8.39 0 0 0-2.024 2.82c-.5 1.11-.75 2.355-.75 3.737a9.124 9.124 0 0 0 .75 3.753 8.524 8.524 0 0 0 2.024 2.853 9.104 9.104 0 0 0 2.994 1.817c2.345.861 4.92.861 7.265 0a9.35 9.35 0 0 0 2.995-1.817 8.418 8.418 0 0 0 2.038-2.854 9.137 9.137 0 0 0 .749-3.754c0-1.38-.25-2.627-.75-3.736a8.287 8.287 0 0 0-2.037-2.82zm-2.841 8.153a4.36 4.36 0 0 1-.768 1.41c-.343.419-.773.759-1.26.997a4.218 4.218 0 0 1-3.471 0 3.755 3.755 0 0 1-1.257-1 4.365 4.365 0 0 1-.767-1.41 5.122 5.122 0 0 1 0-3.193 4.15 4.15 0 0 1 .749-1.376 3.79 3.79 0 0 1 1.242-.968 4.216 4.216 0 0 1 3.47 0c.48.235.909.564 1.26.968.344.405.61.872.782 1.376.18.513.272 1.053.272 1.597 0 .541-.085 1.08-.253 1.594zM98.048 9.803 95.08 20.529h-.069L91.874 9.803h-5.63l-3.036 10.726h-.102L80.172 9.803h-5.99l5.832 16.856h5.632l3.26-10.508h.067l3.328 10.508h5.632l5.865-16.856Z"
    />
    <path
      style={{
        strokeWidth: 0.199755,
      }}
      fill="#2c62cb"
      d="M14.012 0c-3.717 0-7.28 1.474-9.908 4.097A13.975 13.975 0 0 0 0 13.987v.034l3.64.776c3.552.76 7.251.445 10.625-.903a15.775 15.775 0 0 1 8.833-.84l4.924.952v-.02c0-3.709-1.477-7.266-4.104-9.89A14.024 14.024 0 0 0 14.012 0Z"
    />
    <path
      style={{
        strokeWidth: 0.199755,
      }}
      fill="#222234"
      d="m112.378 26.58.044-5.426h-.033l-1.995 5.425h-1.302l-1.937-5.425h-.033l.044 5.425h-1.752v-7.662h2.64l1.748 4.916h.044l1.672-4.916h2.682v7.662zM119.737 20.906a1.678 1.678 0 0 0-.624-.482 1.763 1.763 0 0 0-1.118-.145.97.97 0 0 0-.342.125.841.841 0 0 0-.254.238.632.632 0 0 0-.103.373.592.592 0 0 0 .081.329.723.723 0 0 0 .24.228c.118.071.243.13.373.178.146.054.309.11.488.167.26.087.531.183.813.287.275.102.534.242.768.417.232.175.428.393.575.644.16.293.24.624.227.957a2.49 2.49 0 0 1-.244 1.143c-.155.312-.38.585-.658.796a2.82 2.82 0 0 1-.944.465 3.982 3.982 0 0 1-1.096.151 4.538 4.538 0 0 1-1.595-.287 3.376 3.376 0 0 1-1.28-.817l1.216-1.235c.204.244.457.441.743.58.283.148.597.228.917.232.135 0 .27-.014.401-.043.122-.026.238-.074.342-.142a.709.709 0 0 0 .234-.26.82.82 0 0 0 .086-.389.613.613 0 0 0-.11-.368 1.064 1.064 0 0 0-.308-.275 2.517 2.517 0 0 0-.5-.228 19.916 19.916 0 0 0-.677-.219 6.482 6.482 0 0 1-.722-.281 2.411 2.411 0 0 1-.629-.417 1.932 1.932 0 0 1-.444-.618 2.087 2.087 0 0 1-.168-.882 2.166 2.166 0 0 1 .26-1.094c.168-.298.402-.553.683-.748.29-.198.613-.343.954-.428.35-.09.712-.135 1.074-.136.455.002.907.081 1.335.235.44.151.844.39 1.188.703zM127.813 21.274c.013.392-.073.78-.249 1.132-.159.299-.39.554-.672.742a2.965 2.965 0 0 1-.976.411 5.021 5.021 0 0 1-1.149.13h-.922v2.891h-1.855v-7.662h2.818c.395-.002.79.04 1.176.125.34.073.664.209.954.4.271.185.492.434.64.726.166.345.246.723.235 1.105zm-1.854.01a.826.826 0 0 0-.11-.444.76.76 0 0 0-.293-.27 1.263 1.263 0 0 0-.417-.13 3.51 3.51 0 0 0-.483-.033h-.813v1.808h.78c.168 0 .335-.014.5-.044.15-.025.295-.076.428-.15a.814.814 0 0 0 .298-.287.855.855 0 0 0 .11-.45zM14.265 13.897a18.328 18.328 0 0 1-10.626.9L0 14.02a13.976 13.976 0 0 0 4.109 9.889A14.013 14.013 0 0 0 14.019 28a14.036 14.036 0 0 0 9.905-4.102 13.986 13.986 0 0 0 4.099-9.892l-4.924-.951a15.775 15.775 0 0 0-8.834.842Z"
    />
  </svg>
);

export default SvgFlowLogo28;
