import React, { useEffect, useState } from 'react';
import { Box, Text, Divider, Heading, useColorModeValue, Flex, HStack, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import Modal from '../common/ModalComponent';
import { OutlineButton, PrimaryButton, SubmitButton, SubmitButtonDanger } from '../form/Button';
import { FaRegEdit } from '@react-icons/all-files/fa/FaRegEdit';
import { FaRegTrashAlt } from '@react-icons/all-files/fa/FaRegTrashAlt';
import { FaUserPlus } from '@react-icons/all-files/fa/FaUserPlus';
import { DataTable } from '../dashboard/DataTable';
import { FormProvider, useForm } from 'react-hook-form';
import { CreateUnit, EditUnit } from '../../models/Unit';
import { zodResolver } from '@hookform/resolvers/zod';
import FormComponent from '../../components/form/FormComponent';
import { useDispatch } from 'react-redux';
import { selectAllUnits, fetchAllUnits, addUnit, updateUnit, deleteUnit } from '../../features/departmentUnits/departmentUnitsSlice';


export const AccountUnitsCard = (props) => {
  const units = useSelector(selectAllUnits);
  const user = useSelector(state => state.user);
  const customer = useSelector(state => state.customer);
  const dispatch = useDispatch();
  const [isAdmin, setIsAdmin] = useState(false);
  const [psapIdAvailable, setPsapIdAvailable] = useState(false);

  const canAdd = isAdmin && psapIdAvailable;
  const canEdit = isAdmin;
  const canDelete = isAdmin;

  useEffect(() => {
    dispatch(fetchAllUnits());
  }, [dispatch]);

  useEffect(() => {
    if (user && user.role === 'ADMIN') {
      setIsAdmin(true);
    }
  }, [user]);

  useEffect(() => {
    if (customer && customer.psapId) {
      setPsapIdAvailable(true);
    }
  }, [customer]);

  const [unitModalOpen, setUnitModalOpen] = useState(false);

  const unitColumns = [
    {
      Header: 'Unit',
      accessor: 'unit',
    },
    {
      Header: 'Description',
      accessor: 'desc',
    },
    {
      Header: 'Created On',
      accessor: 'createdOn',
    },
    {
      Header: 'Modified On',
      accessor: 'modifiedOn',
    },
    {
      accessor: 'actions',
      maxWidth: 100,
      Cell: ({ value, row }) => {
        return (
          <HStack justifyContent="end" width="100%">
            <EditUnitModal isDisabled={!canEdit} unit={row.original} />
            <DeleteUnitModal isDisabled={!canDelete} unit={row.original} />
          </HStack>
        );
      }
    }
  ];

  return (
    <Box marginTop="2rem" minWidth="32rem" bg={useColorModeValue('white', 'gray.900')} p="1rem" borderWidth="1px" borderRadius="0.5rem" boxShadow="md">
      <Flex px="1rem" align="center" justify="space-between">
        <Heading as="h4" size="md">Units</Heading>
        <PrimaryButton isDisabled={!canAdd} onClick={() => setUnitModalOpen(true)} aria-label="Add Unit" leftIcon={<FaUserPlus />}>Add Unit</PrimaryButton>
      </Flex>
      <Divider my="0.5rem" />
      <DataTable
        globalSearch
        columns={unitColumns}
        data={units}
        initialSortBy={[
          {
            id: 'unit',
            desc: false
          }
        ]}
      />
      <AddUnitModal isOpen={unitModalOpen} closeModal={() => setUnitModalOpen(false)} />
    </Box>
  );
};

const formFields = [
  {
    id: 'unit',
    name: 'unit',
    type: 'text',
    label: 'Unit'
  },
  {
    id: 'desc',
    name: 'description',
    type: 'textarea',
    label: 'Description',
  }
];

const AddUnitModal = (props) => {
  const methods = useForm({
    resolver: zodResolver(CreateUnit)
  });
  const dispatch = useDispatch();
  const toast = useToast();
  const customer = useSelector(state => state.customer);

  const initialData = {
    unit: '',
    desc: ''
  };

  const onSubmit = async(data) => {
    data.psapId = customer.psapId;

    const resultAction = await dispatch(addUnit(data));

    if (addUnit.fulfilled.match(resultAction)) {
      props.closeModal();
      toast({
        title: 'Unit Created',
        position: 'top',
        description: 'Unit created.',
        status: 'success',
        duration: 2500,
        isClosable: true
      });
    } else {
      toast({
        title: 'Error',
        position: 'top',
        description: 'There was an error creating the unit. Please try again.',
        status: 'error',
        duration: 2500,
        isClosable: true
      });
    }
  };

  return (
    <Modal
      showModal={props.isOpen}
      toggleModal={props.closeModal}
      modal={{
        heading: 'Add Unit',
        body: (<FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormComponent formFields={formFields} data={initialData} />
            <SubmitButton mb="1rem" isLoading={methods.formState.isSubmitting} mt={4} colorScheme="blue" type="submit">
              Submit
            </SubmitButton>
          </form>
        </FormProvider >)
      }}
    />
  );
};

const EditUnitModal = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const methods = useForm({
    resolver: zodResolver(EditUnit)
  });
  const dispatch = useDispatch();
  const toast = useToast();

  const initialData = {
    unit: props.unit.unit,
    desc: props.unit.desc
  };

  const onSubmit = async(data) => {
    const patchData = [];
    const opVal = (path, value) => (value !== undefined && value !== null) && patchData.push({
      op: 'replace',
      path: `/${path}`,
      value
    });

    opVal('unit', data.unit);
    opVal('desc', data.desc);

    const resultAction = await dispatch(updateUnit({ unitId: props.unit.id, data: patchData }));

    if (updateUnit.fulfilled.match(resultAction)) {
      setModalOpen(false);
      toast({
        title: 'Success',
        position: 'top',
        description: 'Unit updated successfully.',
        status: 'success',
        duration: 2500,
        isClosable: true
      });
    } else {
      toast({
        title: 'Error',
        position: 'top',
        description: 'There was an error updating the unit. Please try again.',
        status: 'error',
        duration: 2500,
        isClosable: true
      });
    }
  };

  return (<React.Fragment>
    <OutlineButton
      isDisabled={props.isDisabled}
      size="sm"
      aria-label="Edit unit"
      color="gray.900"
      leftIcon={<FaRegEdit />}
      onClick={() => setModalOpen(true)}
    >Edit</OutlineButton>
    <Modal
      showModal={modalOpen}
      toggleModal={() => setModalOpen(false)}
      modal={{
        heading: 'Update Unit',
        body: (<FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormComponent formFields={formFields} data={initialData} />
            <SubmitButton mb="1rem" isLoading={methods.formState.isSubmitting} mt={4} colorScheme="blue" type="submit">
              Submit
            </SubmitButton>
          </form>
        </FormProvider>)
      }}
    />
  </React.Fragment>);
};

const DeleteUnitModal = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();

  const onDelete = async() => {
    setIsLoading(true);

    const resultAction = await dispatch(deleteUnit(props.unit.id));

    if (deleteUnit.fulfilled.match(resultAction)) {
      setModalOpen(false);
      toast({
        title: 'Success',
        position: 'top',
        description: 'Unit deleted successfully.',
        status: 'success',
        duration: 2500,
        isClosable: true
      });
    } else {
      toast({
        title: 'Error',
        position: 'top',
        description: 'There was an error deleting this unit, please try again.',
        status: 'error',
        duration: 2500,
        isClosable: true
      });
    }
    setIsLoading(false);
  };

  return (<React.Fragment>
    <OutlineButton
      isDisabled={props.isDisabled}
      size="sm"
      aria-label="Delete Unit"
      color="red.400"
      leftIcon={<FaRegTrashAlt />}
      onClick={() => setModalOpen(true)}
    >Delete</OutlineButton>
    <Modal
      showModal={modalOpen}
      toggleModal={() => setModalOpen(false)}
      modal={{
        heading: 'Confirm Delete',
        body: (<Text>Are you sure you want to delete this unit?</Text>),
        footer: <SubmitButtonDanger isLoading={isLoading} onClick={onDelete}>Delete</SubmitButtonDanger>
      }}
    />
  </React.Fragment>);
};
